.frame-type-dropdown {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  min-width: 150px;
}

.frame-type-dropdown-content {
  display: flex;
  flex-direction: column;
}

.frame-type-option {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.frame-type-option:hover {
  background-color: #f5f5f5;
}

.frame-type-option.current {
  font-weight: bold;
  background-color: #f0f0f0;
}

.frame-type-confirmation-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}

.frame-type-confirmation-content {
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  width: 300px;
  max-width: 90%;
}

.frame-type-confirmation-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.frame-type-cancel-btn,
.frame-type-confirm-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.frame-type-cancel-btn {
  background-color: #f0f0f0;
}

.frame-type-confirm-btn {
  background-color: #4caf50;
  color: white;
}

.frame-type-error {
  color: #f44336;
  margin-top: 10px;
}
