.status-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  min-width: 120px;
}

.status-dropdown-content {
  padding: 5px 0;
}

.status-option {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.status-option:hover {
  background-color: #f5f5f5;
}

.status-option.current {
  font-weight: bold;
  background-color: #f0f0f0;
}

.status-confirmation-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.status-confirmation-content {
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  width: 300px;
  max-width: 90%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.status-confirmation-content h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

.status-confirmation-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.status-cancel-btn,
.status-confirm-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  margin-left: 10px;
}

.status-cancel-btn {
  background-color: #f5f5f5;
  color: #333;
}

.status-confirm-btn {
  background-color: #1976d2;
  color: white;
}

.status-cancel-btn:hover {
  background-color: #e0e0e0;
}

.status-confirm-btn:hover {
  background-color: #1565c0;
}

.status-cancel-btn:disabled,
.status-confirm-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.status-error {
  color: #d32f2f;
  margin-top: 10px;
  font-size: 14px;
}

/* Status badge colors - matching existing styles */
.status-option[data-status="נקלט"] {
  color: #856404;
  background-color: #fff3cd;
}

.status-option[data-status="הודפס"] {
  color: #004085;
  background-color: #cce5ff;
}

.status-option[data-status="מוכן"] {
  color: #155724;
  background-color: #d4edda;
}
