.batch-print-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  justify-content: center;
  align-items: center;
}

.batch-print-modal.open {
  display: flex;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.modal-header h2 {
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #757575;
}

.modal-controls {
  display: flex;
  gap: 10px;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  flex-wrap: wrap;
  align-items: center;
}

.modal-controls input[type="text"] {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex-grow: 1;
}

.show-all-checkbox, .update-status-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.select-all-button, .print-selected-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.select-all-button {
  background-color: #f0f0f0;
}

.print-selected-button {
  background-color: #4CAF50;
  color: white;
}

.print-selected-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.orders-list {
  overflow-y: auto;
  padding: 0 20px 20px;
  flex-grow: 1;
}

.orders-list table {
  width: 100%;
  border-collapse: collapse;
}

.orders-list th, .orders-list td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.orders-list th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

/* Status column styling */
.status-column {
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s;
}

.status-column:hover {
  background-color: #f5f5f5;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  transition: transform 0.1s;
}

.status-column:hover .status-badge {
  transform: scale(1.05);
}

.status-dropdown {
  padding: 2px 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 12px;
  background-color: white;
  cursor: pointer;
}

.status-dropdown:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.status-נקלט {
  background-color: #FFF9C4;
}

.status-הודפס {
  background-color: #BBDEFB;
}

.status-מוכן {
  background-color: #C8E6C9;
}

.status-בוטל {
  background-color: #FFCDD2;
}

.print-result {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
}

.print-result.success {
  background-color: #C8E6C9;
  color: #2E7D32;
}

.print-result.error {
  background-color: #FFCDD2;
  color: #C62828;
}

.no-orders-message {
  text-align: center;
  padding: 20px;
  color: #757575;
}
