.print-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  pointer-events: none; /* Allow clicks to pass through to elements below */
}

.print-notification.hidden {
  transform: translateY(100%);
  display: none;
}

.print-notification.sliding-up {
  transform: translateY(0);
}

.print-notification.visible {
  transform: translateY(0);
}

.print-notification.sliding-down {
  transform: translateY(100%);
}

.print-notification-content {
  background-color: #2c3e50;
  color: white;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  padding: 16px;
  display: flex;
  align-items: center;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  pointer-events: auto; /* Make the content clickable */
}

.print-notification-icon {
  font-size: 24px;
  margin-right: 16px;
}

.print-notification-message {
  flex: 1;
}

.print-notification-title {
  font-weight: bold;
  margin-bottom: 4px;
}

.print-notification-queue-id {
  font-size: 0.9em;
  opacity: 0.8;
}

.print-notification-close {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 0 8px;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.print-notification-close:hover {
  opacity: 1;
}

/* Mobile styles */
@media (max-width: 768px) {
  .print-notification-content {
    border-radius: 0;
    width: 100%;
    max-width: 100%;
  }
  
  .print-notification-icon {
    font-size: 20px;
    margin-right: 12px;
  }
  
  .print-notification-title {
    font-size: 0.9em;
  }
  
  .print-notification-queue-id {
    font-size: 0.8em;
  }
}
