.confirmation-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .confirmation-popup h3 {
    margin-top: 0;
    color: #333;
    text-align: center;
  }
  
  .global-preset {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .global-preset select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .image-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 20px;
    padding: 20px 0;
  }
  
  .image-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
  }
  
  .image-preview {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .image-name {
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .image-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  
  .image-controls select {
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .greyscale-checkbox {
    margin-top: 5px;
  }
  
  .remove-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 5px;
  }
  
  .remove-button:hover {
    background-color: #d32f2f;
  }
  
.confirmation-actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.confirmation-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.confirmation-options label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.confirmation-options input[type="checkbox"] {
  margin: 0;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-between;
}
  
.confirm-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: bold;
  margin-top: 10px;
}
  
  .confirm-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .confirm-button:hover {
    background-color: #45a049;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #d32f2f;
  }
  
  .confirm-button:disabled,
  .cancel-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
