.dashboard {
  padding: 20px;
  background-color: #FFFDFB;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-header h1 {
  font-size: 24px;
  margin: 0;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.btn-primary {
  background-color: #28a745;
  color: white;
  border: none;
}

.btn-secondary {
  background-color: white;
  color: #28a745;
  border: 1px solid #28a745;
}

.summary-cards {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  margin-bottom: 20px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (min-width: 771px) and (max-width: 1200px) {
  .summary-cards {
    grid-template-columns: repeat(7, 1fr);
    gap: 0.75rem;
    padding: 0 15px;
  }
}

@media screen and (min-width: 771px) and (max-width: 1200px) {
  .summary-cards {
    gap: 0.75rem;
    padding: 0 15px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .summary-cards {
    gap: 1rem;
    padding: 0 20px;
  }
}

@media screen and (min-width: 1501px) {
  .summary-cards {
    gap: 2rem;
    padding: 0 30px;
    justify-content: center;
  }
}

.printed-frames-status {
  display: flex;
  justify-content: center;
  gap: clamp(1rem, 2vw, 4rem);
  margin-bottom: 20px;
  padding: clamp(12px, 1.5vw, 20px);
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 0 20px 20px 20px;
}

@media screen and (min-width: 771px) and (max-width: 1200px) {
  .printed-frames-status {
    gap: 1.5rem;
  }
}

@media screen and (min-width: 1201px) {
  .printed-frames-status {
    gap: 3rem;
  }
}

.frame-count {
  display: flex;
  align-items: center;
  gap: 10px;
}

.frame-label {
  font-size: 14px;
  color: #6c757d;
}

.frame-value {
  font-size: 16px;
  font-weight: bold;
  color: #495057;
}

.filter-wrapper {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  gap: 1rem;
}

.filter-wrapper .filter-input {
  justify-self: center;
  width: 300px;
}

.filter-wrapper .filter-tabs {
  justify-self: end;
}

@media screen and (min-width: 771px) and (max-width: 1200px) {
  .filter-wrapper {
    padding: 0 15px;
  }
}

@media screen and (min-width: 1201px) {
  .filter-wrapper {
    padding: 0 20px;
  }
}

.filter-tabs {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  flex-wrap: nowrap;
}

@media screen and (min-width: 771px) and (max-width: 1200px) {
  .filter-tabs {
    gap: 6px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .filter-tabs {
    gap: 8px;
  }
}

@media screen and (min-width: 1501px) {
  .filter-tabs {
    gap: 10px;
  }
}

.filter-tab {
  padding: 8px 16px;
  background-color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-tab span {
  margin-left: 8px;
  font-size: 0.8em;
  color: #666;
}

.filter-tab.active {
  background-color: #e9ecef;
  font-weight: 600;
}

.order-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}

.filter-input {
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 200px;
}

.control-buttons {
  display: flex;
  gap: 10px;
}

.control-btn {
  padding: 8px 16px;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
}

.create-order-button, .batch-print-button {
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 16px;
  transition: background-color 0.3s;
}

.create-order-button {
  background-color: #4CAF50;
}

.create-order-button:hover {
  background-color: #45a049;
}

.batch-print-button {
  background-color: #2196F3;
}

.batch-print-button:hover {
  background-color: #0b7dda;
}

@media screen and (max-width: 1500px) {
  .filter-tab {
    padding: 6px 12px;
    font-size: 13px;
  }
  
  .order-controls {
    margin-right: 0;
  }
  
  .create-order-button, .batch-print-button {
    margin-right: 12px;
    padding: 6px 12px;
  }
  
  .filter-input {
    width: 180px;
  }
}
