.mobile-confirmation-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.mobile-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.mobile-global-preset {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.mobile-global-preset label {
  margin-bottom: 5px;
  font-weight: bold;
}

.mobile-global-preset select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
}

.mobile-image-list {
  max-height: 50vh;
  overflow-y: auto;
  margin-bottom: 15px;
}

.mobile-image-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.mobile-image-preview {
  width: 100%;
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 8px;
}

.mobile-image-name {
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  word-break: break-all;
}

.mobile-image-controls {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mobile-image-controls select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
}

.mobile-greyscale-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mobile-remove-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}

.mobile-confirmation-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile-confirmation-options {
  margin-bottom: 10px;
}

.mobile-confirmation-options label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mobile-confirm-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
}

.mobile-confirm-button:disabled {
  background-color: #a5d6a7;
  cursor: not-allowed;
}

.mobile-cancel-button {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.mobile-cancel-button:disabled {
  color: #999;
  cursor: not-allowed;
}
